import React from 'react';
import { NavLink } from 'react-router-dom';
import { nav } from 'bootstrap';
import '../css/Bootstrap-Custom.css';
import '../css/navbar.css';
import logo from '../clubLogo.jpg';

export function SiteNavbar() {
	return (
		<nav className="navbar navbar-expand-lg navbar-custom navbar-dark">
			<div className="container-fluid">
				<span className="navbar-brand">
					<NavLink to="/admin">
						<img src={logo} className="App-logo" alt="logo" />
					</NavLink>
				</span>
				<div className="d-lg-none appName">RangeWarden</div>
				<div className="d-none d-lg-block col-lg-4 appName text-center">
					RangeWarden
				</div>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					id="navbarNav"
					className="collapse navbar-collapse justify-content-md-left"
				>
					<ul className="navbar-nav nav-tabs">
						<li className="nav-item px-5">
							<NavLink
								activeclassname="active"
								className="nav-link text-white"
								to="/"
							>
								Home
							</NavLink>
						</li>
						<li className="nav-item px-5">
							<NavLink
								activeclassname="active"
								className="nav-link text-white"
								to="/visitor"
							>
								Visitor Sign In
							</NavLink>
						</li>
						<li className="nav-item px-5">
							<NavLink
								activeclassname="active"
								className="nav-link text-white"
								to="/signout"
							>
								Sign Out
							</NavLink>
						</li>
						<li className="nav-item px-5">
							<NavLink
								activeclassname="active"
								className="nav-link text-white"
								to="/pcodechange"
							>
								Change Passcode
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}
