import React from 'react';
import '../../css/Bootstrap-Custom.css';
import { CreateNewMember } from './CreateNewMember.js';
import { SignInAudit } from './SignInAudit.js';
import { OnsiteNow } from './CurrentOnsite.js';
import { DataExtraction } from './DataExtraction.js';

export function Admin() {
    
    return (
        <>
        <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="current-onsite-tab" data-bs-toggle="tab" data-bs-target="#current-onsite" type="button" role="tab" aria-controls="current-onsite" aria-selected="true">Onsite Now</button>
                <button className="nav-link" id="signin-audit-tab" data-bs-toggle="tab" data-bs-target="#signin-audit" type="button" role="tab" aria-controls="signin-audit" aria-selected="false">Sign In Register Audit</button>
                <button className="nav-link" id="add-user-tab" data-bs-toggle="tab" data-bs-target="#add-user" type="button" role="tab" aria-controls="add-user" aria-selected="false">Add New Member</button>
                <button className="nav-link" id="data-extract-tab" data-bs-toggle="tab" data-bs-target="#data-extract" type="button" role="tab" aria-controls="data-extract" aria-selected="false">Data Extracts</button>
            </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="current-onsite" role="tabpanel" aria-labelledby="current-onsite-tab"><OnsiteNow /></div>
            <div className="tab-pane fade" id="signin-audit" role="tabpanel" aria-labelledby="signin-audit-tab"><SignInAudit /></div>
            <div className="tab-pane fade" id="add-user" role="tabpanel" aria-labelledby="add-user-tab"><CreateNewMember /></div>
            <div className="tab-pane fade" id="data-extract" role="tabpanel" aria-labelledby='data-extract-tab'><DataExtraction /></div>
        </div>
        </>
    );
}