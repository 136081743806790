import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Bootstrap-Custom.css';
var CryptoJS = require("crypto-js");

export function SignOut() {
    const [isVisitor, setIsVisitor] = useState(false);
    const [visitors, setVisitors] = useState([]);
    const [memberPNZ, setMemberPNZ] = useState("");
    const [selectedVisitor, setSelectedVisitor] = useState("");
    const [isSuccessSignOut, setIsSuccessSignOut] = useState(false);
    const [isFailureSignOut, setIsFailureSignOut] = useState(false);
    
    const navigate = useNavigate();
    const [timerId, setTimerId] = useState(null);

    const key = process.env.REACT_APP_ENCRYPT;
    const getCurrentVisitors = process.env.REACT_APP_API_URL + '/currentVisitors';
    const signOutUser = process.env.REACT_APP_API_URL + '/signOutUser';

    function handleRadioChange(event) {
        setIsVisitor(event.target.value === "visitor");
        if(event.target.value === "visitor"){
            // call the api to fetch the visitors list
            fetch(getCurrentVisitors)
                .then(response => response.text())
                .then(data => {
                    // Decrypt request
                    var bytes = CryptoJS.AES.decrypt(data, key);
                    var decryptedString = bytes.toString(CryptoJS.enc.Utf8);

                    // Convert request to JSON
                    let params = JSON.parse(decryptedString);

                    setVisitors(params);
                    setSelectedVisitor("");
                });
        }
    }

    function handleVisitorChange(event) {
        setSelectedVisitor(event.target.value);
    }

    function handleMemberPNZChange(event) {
        setMemberPNZ(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        // Ensure all fields are completed
        if (isVisitor) {
            // Sign out visitor               
            const visitorSignOut = {
                SignOut: 'Visitor',
                ID: selectedVisitor,
                Name: selectedVisitor
            }

            const encryptedVisitorData = CryptoJS.AES.encrypt(JSON.stringify(visitorSignOut), key);
            
            fetch(signOutUser, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedVisitorData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'User signed out') {
                        setIsSuccessSignOut(true);
                        setTimeout(() => {
                            setIsSuccessSignOut(false);
                            navigate('/');
                        }, 4000);
                    }
                    else {
                        setIsFailureSignOut(true);
                        setTimeout(() => {
                            setIsFailureSignOut(false);
                        }, 3000);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else {
            // Sign out member
            const memberSignOut = {
                SignOut: 'Member',
                User: memberPNZ
            }

            const encryptedMemberData = CryptoJS.AES.encrypt(JSON.stringify(memberSignOut), key);
            
            fetch(signOutUser, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedMemberData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'User signed out') {
                        setIsSuccessSignOut(true);
                        const id = setTimeout(() => {
                            handleCloseModal();
                            navigate('/');
                        }, 5000);
                        setTimerId(id);
                    }
                    else {
                        setIsFailureSignOut(true);
                        setTimeout(() => {
                            setIsFailureSignOut(false);
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    function handleCancel(event) {
        navigate('/');
    };

    const handleCloseModal = () => {
        setIsSuccessSignOut(false);
        setIsFailureSignOut(false);
        clearTimeout(timerId);
        setTimerId(null);
        navigate('/');
    };

    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-md-10 mx-auto col-lg-5'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>Sign Out</h4>
                        <div className='mb-3'>
                            <input type="radio" className="btn-check" name='member' id="member" value='member' autoComplete='off' checked={!isVisitor} onChange={handleRadioChange} required/>
                            <label className="btn btn-outline-success mx-1" htmlFor="member">Member</label>
                            <input type="radio" className="btn-check" name='visitor' id="visitor" value='visitor' autoComplete='off' checked={isVisitor} onChange={handleRadioChange}/>
                            <label className="btn btn-outline-success mx-1" htmlFor="visitor">Visitor</label>
                        </div>
                        {!isVisitor ? (
                            <div className='form-floating mb-3'>
                                <input id='User' className='form-control' type='number' inputtype='number' value={memberPNZ} onChange={handleMemberPNZChange} placeholder='PNZ Member Number' required/>
                                <label htmlFor='User'>PNZ Member Number:</label>
                            </div>
                        ) : (
                            <select className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleVisitorChange}>
                                <option value='blank'>Select Visitor:</option>
                                {visitors.map((visitor) => (
                                    <option value={visitor.visitorID} key={visitor.id}>
                                        {visitor.Name}
                                    </option>
                                ))}
                            </select>
                        )}
                        <div className='my-3'>
                            <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>Sign Out</button>
                            <button className='w-25 btn btn-lg btn-danger rounded mx-1' onClick={handleCancel} onTouchEnd={handleCancel}>Cancel</button>
                        </div>
                    </form>
                </div>
                {isSuccessSignOut && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-success">
                                    <h5 className="modal-title">Success</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You have been succesfully signed out.<br /><br />See you next week!</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFailureSignOut && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>An error has occured. <br /><b>Please check your PNZ Number.</b><br />If this continues to occur, please contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}