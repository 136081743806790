import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import financialYearChoices from '../../resources/financialYears.json';
import '../../css/Bootstrap-Custom.css';

var CryptoJS = require("crypto-js");

export function DataExtraction() {
    const [committeePNZ, setCommitteePNZ] = useState("");
    const [reqYear, setReqYear] = useState([]);
    const [isNotPermitted, setIsNotPermitted] = useState(false);

    const navigate = useNavigate();

    function handleCommitteePNZChange(event) {
        setCommitteePNZ(event.target.value);
    }
    
    function handleReqYearChange(event) {
        setReqYear(event.target.value);
    }

    function resetFields() {
        setCommitteePNZ('');
    }

    const handleCloseModal = () => {
        setIsNotPermitted(false);
        setCommitteePNZ('');
        navigate('/');
    };

    function handleEOFYReportSubmit(event) {
        if(reqYear && committeePNZ) {
            event.preventDefault();
            if (!committeePNZ) {
                alert("Please enter your PNZ Member Number");
                return;
            }

            if (!reqYear) {
                alert("Please select the year you wish to retrieve the report for");
                return;
            }

            // Load encrypt key & API URL
            const key = process.env.REACT_APP_ENCRYPT;
            const EOFYDataReport = process.env.REACT_APP_API_URL + '/EOFYReport';

            //Encrypt Request data
            const reqData = {
                User: committeePNZ,
                FY: reqYear
            };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reqData), key);

            fetch(EOFYDataReport, {
                headers: {
                    'Content-Type' : 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedData
            })
                .then(response => response.json())
                .then(data => {
                    if(data.message === 'Invalid Committee Member' || data.message === 'Invalid Member Number') {
                        setIsNotPermitted(true);
                        setTimeout(() => {
                            setIsNotPermitted(false);
                            navigate('/');
                        }, 5000);
                    }
                    else {
                        console.log(data);
                        let decryptedData = CryptoJS.AES.decrypt(data.message, key).toString(CryptoJS.enc.Utf8);
                        let jsonData = JSON.parse(decryptedData);

                        //Define columns for the CSV
                        const columns = ['AttendType','ID','Title','Gender','FirstName','Surname','licenceNumber','EventDate'];
                        //Create a header row for the CSV
                        const headerRow = columns.join(',') + '\n';
                        // Create CSV rows
                        const csvRows = jsonData.map(entry => columns.map(column => entry[column]).join(','));
                        // Combine header and rows into a single CSV string
                        const formattedCSV = headerRow + csvRows.join('\n');

                        // Create a Blob from the formatted CSV data
                        let blob = new Blob([formattedCSV], { type: 'text/csv' });

                        // Get the current year from the reqYear variable
                        const currentYear = parseInt(reqYear.substring(0, 4));
                        // Calculate the next year
                        const nextYear = currentYear + 1;

                        // Get the current date in YYYY-MM-DD format
                        const today = new Date();
                        const currentDate = today.toISOString().split('T')[0];

                        // Create the filename
                        const fileName = `EOFYReport--FY${currentYear}-${nextYear}--${currentDate}.csv`;

                        // Create a temporary URL for the Blob
                        let url = URL.createObjectURL(blob);

                        // Create a link for downloading the CSV file
                        let link = document.createElement('a');
                        link.href = url;
                        //let fileName = 'EOFYReport' + reqYear.split() + '.csv';
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        // Clean up the temporary URL
                        URL.revokeObjectURL(url);
                    }
                })
        }
    }

    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-lg-5 text-center text-lg-start'>
                    <h5 className='text-danger'>Running a data extract must NOT be performed on sign in tablets</h5>
                    <p>
                        Use of this page will lead to the data being downloaded to your device as a CSV file.
                        <br />
                        <br />
                        Once the data has been downloaded, use Excel to create a pivot table with the Row options of:
                        <br />
                        <ul>
                            <li>EventDate</li>
                            <li>Title</li>
                            <li>FirstName</li>
                            <li>Surname</li>
                            <li>licenceNumber</li>
                        </ul>
                        <br />
                        It's important to use this order to create the Pivot table as then the data will be perfectly displayed, ready to report to Police Clubs and Ranges.
                    </p>
                </div>
                <div className='col-md-10 mx-auto col-lg-7'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>End Of Financial Year Report</h4>
                        <div className='row'>
                            <div className='form-floating col mb-1'>
                                <input id='User' className='form-control' type='number' inputtype='number' value={committeePNZ} onChange={handleCommitteePNZChange} placeholder='PNZ Member Number' required/>
                                <label htmlFor='User' className='px-4'>Committee Member Number:</label>
                            </div>
                            <div className='form-floating col mb-1'>
                                <select name="yearRange" className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleReqYearChange} value={reqYear} required>
                                    <option value='' key=''></option>
                                    {financialYearChoices.map((financialYear, index) => (
                                        <option key={financialYear.Value} value={financialYear.Value}>
                                            {financialYear.Year}
                                        </option>
                                ))}
                                </select>
                                <label htmlFor='yearRange' className='px-4'>Select Report Year:</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='my-1'>
                                <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleEOFYReportSubmit} onTouchEnd={handleEOFYReportSubmit}>Download</button>
                                <button className='w-25 btn btn-lg btn-danger rounded mx-1' onClick={resetFields} onTouchEnd={resetFields}>Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
                {isNotPermitted && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You are not permitted to use this function.<br />If you believe you should be permitted, please contact the Club Committee and they will notify the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}