import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dotenv from 'dotenv';
import '../../css/Bootstrap-Custom.css';
var CryptoJS = require("crypto-js");

export function CreateNewMember() {
    const [firstName, setFirstName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [lastName, setLastName] = useState("");
    const [genders, setGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState("");
    const [titles, setTitles] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [addedBy, setAddedBy] = useState("");
    const [memberPNZ, setMemberPNZ] = useState("");
    const [isSuccessNewMemberCreated, setIsSuccessNewMemberCreated] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [isInvalidCommitteeMember, setIsInvalidCommitteeMember] = useState(false);
    const [isAlreadyExistingMember, setMemberExists] = useState(false);

    const navigate = useNavigate();
    const [timerId, setTimerId] = useState(null);

    dotenv.config({path: '.env'});

    const titleLookup = process.env.REACT_APP_API_URL + '/title';
    const genderLookup = process.env.REACT_APP_API_URL + '/gender';
    const addNewMember = process.env.REACT_APP_API_URL + '/addNewMember';

    // Get titles & genders
    useEffect(() => {
        fetch(titleLookup)
            .then(response => response.json())
            .then(data => {
                setTitles(data);
                setSelectedTitle("");
            })
        
        fetch(genderLookup)
            .then(response => response.json())
            .then(data => {
                setGenders(data);
                setSelectedGender("");
            })
    },[]);

    // Handle option / field changes
    function handleFirstNameChange(event) {
        setFirstName(event.target.value);
    }

    function handlePreferredNameChange(event) {
        setPreferredName(event.target.value);
    }
    
    function handleLastNameChange(event) {
        setLastName(event.target.value);
    }

    function handleGenderChange(event) {
        setSelectedGender(event.target.value);
    }

    function handleTitleChange(event) {
        setSelectedTitle(event.target.value);
    }

    function handleAddedByChange(event) {
        setAddedBy(event.target.value);
    }

    function handleMemberPNZChange(event) {
        setMemberPNZ(event.target.value);
    }

    // Handle form submission
    function handleSubmit(event){
        if(firstName && lastName && selectedTitle && selectedGender && memberPNZ && addedBy) {
            event.preventDefault();
            
            if (!selectedTitle) {
                alert("Please enter new member's title.");
                return;
            }

            if (!selectedGender) {
                alert("Please enter new member's gender.");
                return;
            }

            const key = process.env.REACT_APP_ENCRYPT;

            // Define JS Object to send to API
            const reqData = {
                Gender: selectedGender,
                Title: selectedTitle,
                FirstName: firstName,
                PreferredName: preferredName,
                LastName: lastName,
                NewMemberPNZNumber: memberPNZ,
                AddedBy: addedBy
            }
            // Encrypt data
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reqData), key);
            // Send to API
            fetch(addNewMember, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Successfully created new member') {
                        setIsSuccessNewMemberCreated(true);
                        const id = setTimeout(() => {
                            handleCloseModal();
                            navigate('/admin');
                        }, 5000);
                        setTimerId(id);
                    }
                    else if (data.message === 'Invalid Committee Member') {
                        setIsInvalidCommitteeMember(true);
                        setTimeout(() => {
                            handleCloseModalNoClear();
                        }, 5000);
                    }
                    else if (data.message === 'Member already exists') {
                        setMemberExists(true);
                        setTimeout(() => {
                            handleCloseModal();
                            navigate('/admin');
                        }, 5000);
                    }
                    else {
                        setIsFailure(true);
                        setTimeout(() => {
                            handleCloseModal();
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    function resetFields() {
        setFirstName('');
        setLastName('');
        setSelectedGender('');
        setSelectedTitle('');
        setAddedBy('');
        setMemberPNZ('');
        setPreferredName('');
    }

    const handleCloseModal = () => {
        setIsSuccessNewMemberCreated(false);
        setIsFailure(false);
        setIsInvalidCommitteeMember(false);
        setMemberExists(false);
        resetFields();
        clearTimeout(timerId);
        setTimerId(null);
        navigate('/admin');
    };

    const handleCloseModalNoClear = () => {
        setIsSuccessNewMemberCreated(false);
        setIsFailure(false);
        setIsInvalidCommitteeMember(false);
        setMemberExists(false);
        setAddedBy('');
    };

    const handleCancellation = () => {
        navigate('/admin');
    };

    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-md-10 mx-auto col-lg-7'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>Add New Member</h4>
                        <div className="row">
                            <div className='col form-floating mb-3'>
                                <input id='addedBy' className='form-control' type='number' inputtype='number' value={addedBy} onChange={handleAddedByChange} placeholder='Committee PNZ Number' required/>
                                <label htmlFor='addedBy' className='px-4'>Added By (Committee Member PNZ Number):</label>
                            </div>
                        </div>
                        <h5>New Member's Details</h5>
                        <div className='row'>
                            <div className='col'>
                                <select name="title" className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleTitleChange} value={selectedTitle} required>
                                    <option value='' key=''>Select Title:</option>
                                    {titles.map((title) => (
                                        <option key={title.id} value={title.id}>
                                            {title.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col'>
                                <select name="gender" className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleGenderChange} value={selectedGender} required>
                                    <option value='' key=''>Select Gender:</option>
                                    {genders.map((gender) => (
                                        <option key={gender.id} value={gender.id}>
                                            {gender.gender}
                                        </option>
                                ))}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col form-floating mb-3'>
                                <input id='firstName' className='form-control' type='text' inputtype='text' value={firstName} onChange={handleFirstNameChange} placeholder='First Name' required/>
                                <label htmlFor='firstName' className='px-4'>First Name:</label>
                            </div>
                            <div className='col form-floating mb-3'>
                                <input id='preferredName' className='form-control' type='text' inputtype='text' value={preferredName} onChange={handlePreferredNameChange} placeholder='Preferred Name'/>
                                <label htmlFor='preferredName' className='px-4'>Preferred Name:</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col form-floating mb-3'>
                                <input id='lastName' className='form-control' type='text' inputtype='text' value={lastName} onChange={handleLastNameChange} placeholder='Last Name' required/>
                                <label htmlFor='lastName' className='px-4'>Last Name:</label>
                            </div>
                            <div className='col form-floating mb-3'>
                                <input id='memberPNZ' className='form-control' type='text' inputtype='number' value={memberPNZ} onChange={handleMemberPNZChange} placeholder='New PNZ Number' required/>
                                <label htmlFor='memberPNZ' className='px-4'>New Member PNZ Number:</label>
                            </div>
                        </div>
                        <div className='my-3'>
                            <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>Add Member</button>
                            <button className='w-20 btn btn-lg btn-danger rounded mx-1' onClick={resetFields} onTouchEnd={resetFields}>Reset</button>
                            <button className='w-20 btn btn-lg btn-danger rounded mx-1' onClick={handleCancellation} onTouchEnd={handleCancellation}>Cancel</button>
                        </div>
                    </form>
                </div>
                {isSuccessNewMemberCreated && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-success">
                                    <h5 className="modal-title">Success</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>The new member has been recorded.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFailure && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>An error has occured.<br />If this continues to occur, please contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isInvalidCommitteeMember && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Invalid Committee Member Number</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>The provided Committee Member number does not have permission to perform this function.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isAlreadyExistingMember && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Member Already Exists</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>This member already exists in the database.<br />They should be able to sign in.<br />If not, the member should contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}