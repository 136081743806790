import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dotenv from 'dotenv';
import '../css/Bootstrap-Custom.css';
var CryptoJS = require("crypto-js");

export function VisitorSignIn() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [range, setRange] = useState("");
    const [genders, setGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState("");
    const [titles, setTitles] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [visitorFAL, setVisitorFAL] = useState("");
    const [supervisingMember, setSupervisingMember] = useState("");
    const [isSuccessVisitor, setIsSuccessVisitorSignIn] = useState(false);
    const [isFailureVisitor, setIsFailureVisitorSignIn] = useState(false);
    const [isInvalidMember, setIsInvalidMember] = useState(false);

    const navigate = useNavigate();
    const [timerId, setTimerId] = useState(null);

    dotenv.config({path: '.env'});

    const titleLookup = process.env.REACT_APP_API_URL + '/title';
    const genderLookup = process.env.REACT_APP_API_URL + '/gender';
    const recordVisitor = process.env.REACT_APP_API_URL + '/recordVisitor';

    // Get titles & genders
    useEffect(() => {
        fetch(titleLookup)
            .then(response => response.json())
            .then(data => {
                setTitles(data);
                setSelectedTitle("");
            })
        
        fetch(genderLookup)
            .then(response => response.json())
            .then(data => {
                setGenders(data);
                setSelectedGender("");
            })
    },[]);

    // Handle option / field changes
    function handleFirstNameChange(event) {
        setFirstName(event.target.value);
    }
    
    function handleLastNameChange(event) {
        setLastName(event.target.value);
    }

    function handleRangeSelectionChange(event) {
        setRange(event.target.value);
    }

    function handleGenderChange(event) {
        setSelectedGender(event.target.value)
    }

    function handleTitleChange(event) {
        setSelectedTitle(event.target.value)
    }

    function handleVisitorFALChange(event) {
        setVisitorFAL(event.target.value)
    }

    function handleSupervisingMemberChange(event) {
        setSupervisingMember(event.target.value)
    }

    // Handle form submission
    function handleSubmit(event){
        if(firstName && lastName && selectedTitle && selectedGender && range) {
            event.preventDefault();
            
            if (!selectedTitle) {
                alert("Please enter your title.");
                return;
            }

            if (!selectedGender) {
                alert("Please enter your gender.");
                return;
            }

            const key = process.env.REACT_APP_ENCRYPT;

            // Define JS Object to send to API
            const reqData = {
                Gender: selectedGender,
                Title: selectedTitle,
                FirstName: firstName,
                LastName: lastName,
                FAL: visitorFAL,
                Member: supervisingMember,
                Range: range
            }
            // Encrypt data
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reqData), key);
            // Send to API
            fetch(recordVisitor, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Successfully recorded visitor visit') {
                        setIsSuccessVisitorSignIn(true);
                        const id = setTimeout(() => {
                            handleCloseModal();
                            navigate('/');
                        }, 5000);
                        setTimerId(id);
                    }
                    else if (data.message === 'Invalid Member') {
                        setIsInvalidMember(true);
                        setTimeout(() => {
                            handleCloseModalNoClear();
                        }, 5000);
                    }
                    else {
                        setIsFailureVisitorSignIn(true);
                        setTimeout(() => {
                            handleCloseModal();
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    function resetFields() {
        setFirstName('');
        setLastName('');
        setVisitorFAL('');
        setSelectedGender('');
        setSelectedTitle('');
        setSupervisingMember('');
        setRange('');
    }

    const handleCloseModal = () => {
        setIsSuccessVisitorSignIn(false);
        setIsFailureVisitorSignIn(false);
        setIsInvalidMember(false);
        resetFields();
        clearTimeout(timerId);
        setTimerId(null);
        navigate('/');
    };

    const handleCloseModalNoClear = () => {
        setIsSuccessVisitorSignIn(false);
        setIsFailureVisitorSignIn(false);
        setIsInvalidMember(false);
        setSupervisingMember('');
    };

    const handleCancellation = () => {
        navigate('/');
    };

    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-lg-5 text-center text-lg-start'>
                    <h3>Welcome to {process.env.REACT_APP_CLUB_NAME}!</h3><br />
                    <p>We hope you enjoy your visit with us today.<br />Please remember to sign out when you leave.</p>
                    <p><i>Please enter the PNZ Number of the club member who will be supervising you at the range today.</i></p>
                    <p className='text-danger'>Familiarise yourself with our Range Standing Orders posted on each range before shooting.</p>
                </div>
                <div className='col-md-10 mx-auto col-lg-7'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>Visitor Sign In</h4>
                        <div className='row'>
                            <div className='col'>
                                <select name="title" className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleTitleChange} value={selectedTitle} required>
                                    <option value='' key=''>Select Title:</option>
                                    {titles.map((title) => (
                                        <option key={title.id} value={title.id}>
                                            {title.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col'>
                                <select name="gender" className="form-select form-select-md mb-3" aria-label=".form-select-md" onChange={handleGenderChange} value={selectedGender} required>
                                    <option value='' key=''>Select Gender:</option>
                                    {genders.map((gender) => (
                                        <option key={gender.id} value={gender.id}>
                                            {gender.gender}
                                        </option>
                                ))}
                                </select>
                            </div>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='firstName' className='form-control' type='text' inputtype='text' value={firstName} onChange={handleFirstNameChange} placeholder='First Name' required/>
                            <label htmlFor='firstName'>First Name:</label>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='lastName' className='form-control' type='text' inputtype='text' value={lastName} onChange={handleLastNameChange} placeholder='Last Name' required/>
                            <label htmlFor='lastName'>Last Name:</label>
                        </div>
                        <div className="row">
                            <div className='col form-floating mb-3'>
                                <input id='FAL' className='form-control' type='text' inputtype='text' value={visitorFAL} onChange={handleVisitorFALChange} placeholder='Visitor FAL'/>
                                <label htmlFor='FAL'>Firearms Licence Number (if held):</label>
                            </div>
                            <div className='col form-floating mb-3'>
                                <input id='supervisingMember' className='form-control' type='number' inputtype='number' value={supervisingMember} onChange={handleSupervisingMemberChange} placeholder='PNZ Number' required/>
                                <label htmlFor='supervisingMember' className='px-4'>Supervising Member's Number:</label>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <input type="radio" className="btn-check" name='range' id="range1" value='1' autoComplete="off" checked={range === "1"} onChange={handleRangeSelectionChange} required/>
                            <label className="btn btn-outline-success mx-1" htmlFor="range1">Range 1</label>
                            <input type="radio" className="btn-check" name='range' id="range2" value='2' autoComplete="off" checked={range === "2"} onChange={handleRangeSelectionChange}/>
                            <label className="btn btn-outline-success mx-1" htmlFor="range2">Range 2</label>
                            <input type="radio" className="btn-check" name='range' id="range3" value='3' autoComplete="off" checked={range === "3"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="range3">Range 3</label>
                            <input type="radio" className="btn-check" name='range' id="archery" value='98' autoComplete="off" checked={range === "98"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="archery">Archery</label>
                            <input type="radio" className="btn-check" name='range' id="clubhouse" value='99' autoComplete="off" checked={range === "99"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="clubhouse">Non Shooting Visit</label>
                        </div>
                        <div className='my-3'>
                            <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>Sign In</button>
                            <button className='w-20 btn btn-lg btn-danger rounded mx-1' onClick={resetFields} onTouchEnd={resetFields}>Reset</button>
                            <button className='w-20 btn btn-lg btn-danger rounded mx-1' onClick={handleCancellation} onTouchEnd={handleCancellation}>Cancel</button>
                        </div>
                    </form>
                </div>
                {isSuccessVisitor && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-success">
                                    <h5 className="modal-title">Success</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You have been succesfully signed in.<br /></p>
                                <p className='text-danger'>Familiarise yourself with our Range Standing Orders posted on each range before shooting.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFailureVisitor && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>An error has occured. <br /><b>Please check your PNZ Number.</b><br />If this continues to occur, please contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isInvalidMember && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Invalid Member Number</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>The provided supervising member number is wrong. <br /><b>Please check your supervisor's PNZ Number.</b><br /></p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}