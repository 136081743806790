import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import '../../css/Bootstrap-Custom.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa2, fa3, faBan, faBullseye, faGenderless, faMars, faMarsAndVenusBurst, faNeuter, faVenus } from '@fortawesome/free-solid-svg-icons';

var CryptoJS = require("crypto-js");

export function SignInAudit() {
    const [committeePNZ, setCommitteePNZ] = useState('');
    const [auditDate, setAuditDate] = useState('');
    const [auditDateFormat, setAuditDateFormat] = useState('');
    const [isNotPermitted, setIsNotPermitted] = useState(false);

    const navigate = useNavigate();

    const handleCommitteePNZChange = (event) => {
        setCommitteePNZ(event.target.value);
        //console.log("CommitteePNZ: " + committeePNZ);
    };

    const handleAuditDateChange = (event) => {
        setAuditDate(event.target.value);
        //console.log("Audit Date: " + auditDate);
    };

    const handleCloseModal = () => {
        setIsNotPermitted(false);
        setCommitteePNZ('');
        setAuditDate('');
        navigate('/');
    };

    function handleSubmit(event){
        if(committeePNZ && auditDate) {
            event.preventDefault();

            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            const formattedDate = new Date(auditDate).toLocaleDateString('en-NZ', options);
            setAuditDateFormat(formattedDate);

            // Load encrypt key & API URL
            const key = process.env.REACT_APP_ENCRYPT;
            const signInAuditAPI = process.env.REACT_APP_API_URL + '/signInAudit?pnzNumber=' + committeePNZ + '&date=' + auditDate;

            fetch(signInAuditAPI)
                .then(response => response.json())
                .then(data => {
                    if(data.message === "Invalid Committee Member") {
                        setIsNotPermitted(true);
                        setTimeout(() => {
                            setIsNotPermitted(false);
                            navigate('/');
                        }, 5000);
                    }
                    else if(data.message === "No data to return") {
                        console.log("No data");
                    }
                    else {
                        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.message, key).toString(CryptoJS.enc.Utf8));
                        //console.log(decryptedData);

                        const tableRows = decryptedData.map(item => {
                            const visitStartObj = new Date(item.visitStart);
                            const visitEndObj = item.visitEnd ? new Date(item.visitEnd) : null;

                            const visitStartTime = `${String(visitStartObj.getHours()).padStart(2, '0')}:${String(visitStartObj.getMinutes()).padStart(2, '0')}`;
                            const visitEndTime = visitEndObj ? `${String(visitEndObj.getHours()).padStart(2, '0')}:${String(visitEndObj.getMinutes()).padStart(2, '0')}` : '-';

                            const genderIcon = item.gender === 'Male' ? <FontAwesomeIcon icon={faMars} /> : (item.gender === 'Female' ? <FontAwesomeIcon icon={faVenus} /> : (item.gender === 'Non-binary' ? <FontAwesomeIcon icon={faNeuter} /> : (item.gender === 'Unknown' ? <FontAwesomeIcon icon={faGenderless} /> : (item.gender === 'Prefer not to say' ? <FontAwesomeIcon icon={faMarsAndVenusBurst} /> : ''))));

                            const getRangeLabel = (range) => {
                                if (range === 98) {
                                    return <FontAwesomeIcon icon={faBullseye} />;
                                } else if (range === 99) {
                                    return <FontAwesomeIcon icon={faBan} />;
                                } else if (range === 1) {
                                    return <FontAwesomeIcon icon={fa1} />;
                                } else if (range === 2) {
                                    return <FontAwesomeIcon icon={fa2} />;
                                } else if (range === 3) {
                                    return <FontAwesomeIcon icon={fa3} />;
                                } else {
                                    return item.range;
                                }
                            };

                            return (
                                <tr key={item.id} className="table-row">
                                    <td>{item.id}</td>
                                    <td>{item.type}</td>
                                    <td>{genderIcon}</td>
                                    <td>{item.title}</td>
                                    <td>{item.firstname}</td>
                                    <td>{item.surname}</td>
                                    <td>{getRangeLabel(item.range)}</td>
                                    <td>{visitStartTime}</td>
                                    <td>{visitEndTime}</td>
                                </tr>
                            );
                        });

                        const rootElement = document.getElementById('table-container');
                        createRoot(rootElement).render(
                        <table className='table table-striped table-sm'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Type</th>
                                <th>Gender</th>
                                <th>Title</th>
                                <th>First Name</th>
                                <th>Surname</th>
                                <th>Range</th>
                                <th>Visit Start Time</th>
                                <th>Visit End Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableRows}
                            </tbody>
                        </table>
                        );
                    };
                });
                }
        }

    function handleCancellation(event){
        setCommitteePNZ('');
        setAuditDate('');
        setAuditDateFormat('');
        navigate('/admin');
    }

    return (
        <div className='container col-xl-10 col-xxl-8 px-0 py-0'>
            <div className='row align-items-center g-lg-9 py-3'>
                <div className='col-md-10 mx-auto col-lg-12'>
                    <form className='p-4 p-md-3 rounded-3 bg-body-tertiary text-center'>
                        <div className="row">
                            <div className='col form-floating mb-3'>
                                <input id='committeePNZ' className='form-control' type='number' inputtype='number' value={committeePNZ} onChange={handleCommitteePNZChange} placeholder='Committee PNZ Number' required/>
                                <label htmlFor='committeePNZ' className='px-4'>Committee Member:</label>
                            </div>
                            <div className='col form-floating mb-3'>
                                <input id='auditDate' className='form-control' type='date' inputtype='date' value={auditDate} onChange={handleAuditDateChange} placeholder='Date to Audit' required/>
                                <label htmlFor='auditDate' className='px-4'>Date to Audit:</label>
                            </div>
                            <div className='col form-floating'>
                                <button className='w-50 btn btn-lg btn-primary rounded mx-1 my-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>View Sign Ins</button>
                                <button className='w-20 btn btn-lg btn-danger rounded mx-1 my-1' onClick={handleCancellation} onTouchEnd={handleCancellation}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='p-4 p-md-3 border rounded-3 bg-body-teritary text-center'>
                    <h5>Sign Ins on {auditDateFormat}</h5>
                    <div id="table-container" className="table-responsive">

                    </div>
                </div>
                {isNotPermitted && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You are not permitted to use this function.<br />If you believe you should be permitted, please contact the Club Committee and they will notify the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}