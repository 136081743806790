import React from 'react';
import '../css/AttendancePopup.css'; // Import your CSS file for styling

function AttendancePopup({ attendanceCount }) {
    return (
        <div className="attendance-popup">
            <h2 className="attendance-popup-heading">Attendance Count This Year</h2>
            <p className="attendance-count">{attendanceCount}</p>
        </div>
    );
}

export default AttendancePopup;