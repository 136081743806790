import React, {useState } from 'react';
import AttendancePopup from './AttendancePopup';
import dotenv from 'dotenv';
import '../css/Bootstrap-Custom.css';
import '../css/MemberSignIn.css';
var CryptoJS = require("crypto-js");


export function MemberSignIn() {
    const [memberPNZ, setMemberPNZ] = useState("");
    const [memberPIN, setMemberPIN] = useState("");
    const [range, setRange] = useState("");
    const [isSuccessMember, setIsSuccessMemberSignIn] = useState(false);
    const [isFailureMember, setIsFailureMemberSignIn] = useState(false);
    const [isAlreadySignedIn, setIsAlreadySignedIn] = useState(false);
    const [attendanceCount, setAttendanceCount] = useState(0);

    dotenv.config({path: '.env'});

    function handleMemberPNZChange(event) {
        setMemberPNZ(event.target.value);
    }
    
    function handleMemberPINChange(event) {
        setMemberPIN(event.target.value);
    }

    function handleRangeSelectionChange(event) {
        setRange(event.target.value);
    }

    function handleSubmit(event) {
        if(memberPNZ && memberPIN && range) {
            event.preventDefault();

            // Load encrypt key & API URL
            const key = process.env.REACT_APP_ENCRYPT;
            const recordMemberAPI = process.env.REACT_APP_API_URL + '/recordMember';

            // Add form fields to JS Object      
            const reqData = {
                User: memberPNZ,
                PIN: memberPIN,
                Range: range
            }

            // Encrypt Data
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reqData), key);

            // Send API request
            fetch(recordMemberAPI, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                mode: 'cors',
                body: encryptedData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Successfully recorded member visit') {
                        setAttendanceCount(data.visitCount);
                        setIsSuccessMemberSignIn(true);
                        setTimeout(() => {
                            handleCloseModal();
                        }, 5000);
                    }
                    else if (data.message === 'Member already signed in') {
                        setIsAlreadySignedIn(true);
                        setTimeout(() => {
                            handleCloseModal();
                        }, 5000);
                    }
                    else {
                        setIsFailureMemberSignIn(true);
                        setTimeout(() => {
                            handleCloseModalNoClear();
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    function resetFields() {
        setMemberPNZ('');
        setMemberPIN('');
        setRange('');
    }

    const handleCloseModal = () => {
        setIsSuccessMemberSignIn(false);
        setIsFailureMemberSignIn(false);
        setIsAlreadySignedIn(false);
        resetFields();
    };

    const handleCloseModalNoClear = () => {
        setIsSuccessMemberSignIn(false);
        setIsFailureMemberSignIn(false);
        setIsAlreadySignedIn(false);
    };
    
    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-lg-5 text-center text-lg-start'>
                    <h3>Welcome to {process.env.REACT_APP_CLUB_NAME}!</h3><br />
                    <p>Please remember to sign out when you leave.</p>
                    <p><i>B Endorsement holders - please note that your visit will only count towards your 12 if you sign out.</i></p>
                </div>
                <div className='col-md-10 mx-auto col-lg-7'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>Member Sign In</h4>
                        <div className='form-floating mb-3'>
                            <input id='User' className='form-control' type='number' inputtype='number' value={memberPNZ} onChange={handleMemberPNZChange} placeholder='PNZ Member Number' required/>
                            <label htmlFor='User'>PNZ Member Number:</label>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='PIN' className='form-control' type='number' inputtype='password' value={memberPIN} onChange={handleMemberPINChange} placeholder='PIN' required/>
                            <label htmlFor='PIN'>Passcode:</label>
                        </div>
                        <div className='mb-3'>
                            <input type="radio" className="btn-check" name='range' id="range1" value='1' autoComplete="off" checked={range === "1"} onChange={handleRangeSelectionChange} required/>
                            <label className="btn btn-outline-success mx-1" htmlFor="range1">Range 1</label>
                            <input type="radio" className="btn-check" name='range' id="range2" value='2' autoComplete="off" checked={range === "2"} onChange={handleRangeSelectionChange}/>
                            <label className="btn btn-outline-success mx-1" htmlFor="range2">Range 2</label>
                            <input type="radio" className="btn-check" name='range' id="range3" value='3' autoComplete="off" checked={range === "3"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="range3">Range 3</label>
                            <input type="radio" className="btn-check" name='range' id="archery" value='98' autoComplete="off" checked={range === "98"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="archery">Archery</label>
                            <input type="radio" className="btn-check" name='range' id="clubhouse" value='99' autoComplete="off" checked={range === "99"} onChange={handleRangeSelectionChange} />
                            <label className="btn btn-outline-success mx-1" htmlFor="clubhouse">Non Shooting Visit</label>
                        </div>
                        <div className='my-3'>
                            <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>Sign In</button>
                            <button className='w-25 btn btn-lg btn-danger rounded mx-1' onClick={resetFields} onTouchEnd={resetFields}>Reset</button>
                        </div>
                    </form>
                </div>
                {isSuccessMember && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-success">
                                    <h5 className="modal-title">Success</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You have been succesfully signed in!<br /><br />Remember to sign out before you leave.</p>
                            </div>
                            </div>
                            <AttendancePopup attendanceCount={attendanceCount} />
                        </div>
                    </div>
                )}
                {isFailureMember && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>An error has occured. <br /><b>Please check your PNZ Number and PIN Number and try again.</b><br />If this continues to occur, please contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isAlreadySignedIn && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} onTouchEnd={handleCloseModal} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>You are already signed in.<br /><br />Remember to sign out before you leave</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}