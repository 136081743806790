import { Routes, Route, Outlet } from 'react-router-dom';
import React, { useEffect} from 'react';
import { SignOut } from './components/SignOut.js';
import { PasscodeChange } from './components/PasscodeChange.js';
import { VisitorSignIn } from './components/VisitorSignIn.js';
import { SiteNavbar } from './components/SiteNavbar.js';
import { MemberSignIn } from './components/MemberSignIn.js';
import { Admin } from './components/admin/Admin.js';

function App() {
    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_NAME;
    }, []);
  
    return (
        <>
        <div className="App">
            <SiteNavbar />
            <p id='#'></p>
            <Routes>
                <Route path="/" element={<MemberSignIn />} />
                <Route path="/signout" element={<SignOut />} />
                <Route path="/pcodechange" element={<PasscodeChange />} />
                <Route path="/visitor" element={<VisitorSignIn />} />
                <Route path="/admin" element={<Admin />} />
            </Routes>
            <Outlet />
        </div>
        </>
    );
}

export default App;