import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dotenv from 'dotenv';
import '../css/Bootstrap-Custom.css';
import '../css/PasscodeChange.css';
var CryptoJS = require("crypto-js");

export function PasscodeChange() {
    const [memberPNZPasscodeChange, setMemberPNZPasscodeChange] = useState("");
    const [memberPINPasscodeChange, setMemberPINPasscodeChange] = useState("");
    const [memberNewPIN, setMemberNewPIN] = useState("");
    const [memberNewPIN2, setMemberNewPIN2] = useState("");
    const [isSuccessPasscodeChange, setIsSuccessPasscodeChange] = useState(false);
    const [isFailurePasscodeChange, setIsFailurePasscodeChange] = useState(false);

    const navigate = useNavigate();
    const [timerId, setTimerId] = useState(null);

    dotenv.config({path: '.env'});

    const changePasscode = process.env.REACT_APP_API_URL + '/changePasscode';

    function handleMemberPNZChange(event) {
        setMemberPNZPasscodeChange(event.target.value);
    }
    
    function handleMemberPINChange(event) {
        setMemberPINPasscodeChange(event.target.value);
    }

    function handleMemberNewPINChange(event) {
        setMemberNewPIN(event.target.value);
    }

    function handleMemberNewPIN2Change(event) {
        setMemberNewPIN2(event.target.value);
    }

    function handleSubmit(event) {
        if(memberPNZPasscodeChange && memberPINPasscodeChange && memberNewPIN && memberNewPIN2) {
            event.preventDefault();

            if (memberNewPIN !== memberNewPIN2) {
                alert("New passcode fields do not match. Please try again.");
                setMemberNewPIN('');
                setMemberNewPIN2('');
                return;
            }
            else {
                // Load encrypt key
                const key = process.env.REACT_APP_ENCRYPT;

                // Add form fields to JS Object      
                const reqData = {
                    User: memberPNZPasscodeChange,
                    PIN: memberPINPasscodeChange,
                    newPIN: memberNewPIN
                }

                // Encrypt Data
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reqData), key);

                // Send API request
                fetch(changePasscode, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    mode: 'cors',
                    body: encryptedData
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.message === 'Successfully updated member passcode') {
                            setIsSuccessPasscodeChange(true);
                            const id = setTimeout(() => {
                                setIsSuccessPasscodeChange(false);
                                navigate('/');
                            }, 5000);
                            setTimerId(id);
                        } else {
                            setIsFailurePasscodeChange(true);
                            const id = setTimeout(() => {
                                setIsFailurePasscodeChange(false);
                            }, 5000);
                            setTimerId(id);
                        }                        
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                }
            }
    }

    function resetFields() {
        setMemberPNZPasscodeChange('');
        setMemberPINPasscodeChange('');
        setMemberNewPIN('');
        setMemberNewPIN2('');
    }

    const handleCloseModalSuccess = () => {
        setIsSuccessPasscodeChange(false);
        resetFields();
        clearTimeout(timerId);
        setTimerId(null);
        navigate('/');
    };

    const handleCloseModalNoClear = () => {
        setIsFailurePasscodeChange(false);
        setMemberNewPIN('');
        setMemberNewPIN2('');
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <div className='container col-xl-10 col-xxl-8 px-4 py-3'>
            <div className='row align-items-center g-lg-5 py-3'>
                <div className='col-md-10 mx-auto col-lg-5'>
                    <form className='p-4 p-md-3 border rounded-3 bg-body-tertiary text-center'>
                        <h4>Change Passcode</h4>
                        <div className='form-floating mb-3'>
                            <input id='User' className='form-control' type='number' inputtype='number' value={memberPNZPasscodeChange} onChange={handleMemberPNZChange} placeholder='PNZ Member Number' required/>
                            <label htmlFor='User'>PNZ Member Number:</label>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='PIN' className='form-control' type='number' inputtype='number' value={memberPINPasscodeChange} onChange={handleMemberPINChange} placeholder='PIN' required/>
                            <label htmlFor='PIN'>Passcode:</label>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='newPIN' className='form-control' type='number' inputtype='number' value={memberNewPIN} onChange={handleMemberNewPINChange} placeholder='PIN' required/>
                            <label htmlFor='newPIN'>New Passcode:</label>
                        </div>
                        <div className='form-floating mb-3'>
                            <input id='PIN' className='form-control' type='number' inputtype='number' value={memberNewPIN2} onChange={handleMemberNewPIN2Change} placeholder='PIN' required/>
                            <label htmlFor='PIN'>Repeat New Passcode:</label>
                        </div>
                        <div className='my-3'>
                            <button className='w-50 btn btn-lg btn-primary rounded mx-1' onClick={handleSubmit} onTouchEnd={handleSubmit}>Change Passcode</button>
                            <button className='w-25 btn btn-lg btn-danger rounded mx-1' onClick={handleCancel} onTouchEnd={handleCancel}>Cancel</button>
                        </div>
                    </form>
                </div>
                {isSuccessPasscodeChange && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-success">
                                    <h5 className="modal-title">Success</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalSuccess} onTouchEnd={handleCloseModalSuccess} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>Your passcode has been successfully changed.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                {isFailurePasscodeChange && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header modal-header-error">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModalNoClear} onTouchEnd={handleCloseModalNoClear} />
                                </div>
                            <div className="modal-body modal-body-colour">
                                <p>An error has occured. <br /><b>Please check your PNZ Number, old passcode and new passcode.</b><br />If this continues to occur, please contact the IT Support Team.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}